import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
  NumberField,
  Button,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { makeStyles } from '@material-ui/core/styles';
import { LinkField } from '../components/fields';
import {
  COLLECTION_APP_BUILD,
  COLLECTION_APP_RESOURCE,
  COLLECTION_APP_VERSION,
} from '../utils';
import { CustomToolbar } from '../components/toolbars';
import JsonFieldUtils from '../components/utils/JsonFieldUtils';
import { appBinaryDownload } from '../authProvider/appBinaryApi';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppBinaryShow: FC<ShowProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  const classes = useStyles();

  const download = async (type: string) => {
    const id = props.id!;
    const response = await appBinaryDownload(id, type);
    const downloadUrl = response.json?.data?.downloadUrl;
    if (!downloadUrl) {
      return;
    }
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${id}.${type}`;
    a.click();
  };

  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <LinkField
            label="Version"
            source="versionId"
            reference={COLLECTION_APP_VERSION}
          />
          <LinkField
            label="Build"
            source="buildId"
            reference={COLLECTION_APP_BUILD}
          />
          <TextField source="status" />
          {canModerate && <NumberField source="iosBitriseId" />}
          <TextField source="ipaStatus" />
          <TextField source="ipaUrl" />
          {canModerate && <NumberField source="androidBitriseId" />}
          <TextField source="aabStatus" />
          <TextField source="aabUrl" />
          {canModerate && (
            <Button
              label="Download AAB"
              size="small"
              onClick={() => download('aab')}
            />
          )}
          <TextField source="apkStatus" />
          <TextField source="apkUrl" />
          {canModerate && (
            <Button
              label="Download APK"
              size="small"
              onClick={() => download('apk')}
            />
          )}
          <TextField source="samsungStatus" />
          <TextField source="samsungUrl" />
          {canModerate && (
            <Button
              label="Download Samsung"
              size="small"
              onClick={() => download('samsung')}
            />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('metadata')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('buildOptions')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('runtimeOptions')} />
          )}
          <DateField source="updatedAt" />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppBinaryShow;
