import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  EditProps,
  FormTab,
  NumberField,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { BinaryStatusOptions } from '../utils';
import { JsonInput } from '../components/inputs';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppBinaryEdit: FC<EditProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar saveable />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <NumberField label="Resource" source="appId" />
          <NumberField source="versionId" />
          <NumberField source="buildId" />
          <SelectInput source="status" choices={BinaryStatusOptions} />
          <SelectInput source="ipaStatus" choices={BinaryStatusOptions} />
          <TextInput source="ipaUrl" />
          <SelectInput source="aabStatus" choices={BinaryStatusOptions} />
          <TextInput source="aabUrl" />
          <SelectInput source="apkStatus" choices={BinaryStatusOptions} />
          <TextInput source="apkUrl" />
          <SelectInput source="samsungStatus" choices={BinaryStatusOptions} />
          <TextInput source="samsungUrl" />
          {canModerate && (
            <>
              <JsonInput source="buildOptions" />
              <JsonInput source="runtimeOptions" />
            </>
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AppBinaryEdit;
