import React from 'react';
import { Button, ShowActionsProps, EditActionsProps } from 'react-admin';
import { startsWith } from 'lodash';

export const ActionResourceButtons: React.FC<
  ShowActionsProps | EditActionsProps
> = props => {
  const handleClickSearchAppStore = () => {
    const title = props.data?.title;
    window.open(`https://www.apple.com/us/search/${title}?src=serp`);
  };
  const handleClickSearchGooglePlay = () => {
    const title = props.data?.title;
    window.open(`https://play.google.com/store/search?q="${title}"&c=apps`);
  };
  const handleClickSearchYoutube = () => {
    const title = props.data?.title;
    window.open(`https://www.youtube.com/results?search_query="${title}"`);
  };
  const handleClickSearchImages = () => {
    const title = props.data?.title;
    window.open(`http://images.google.com/?q="${title}"&tbs=isz:l,iar:s`);
  };
  const handleClickOpenUri = () => {
    window.open(props.data?.metadata?.uri);
  };
  const handleClickOpenInitialUri = () => {
    let initialUri = props.data?.metadata?.initialUri;
    if (
      startsWith(initialUri, 'https://html5.gamedistribution.com') ||
      startsWith(initialUri, 'https://html5.gamemonetize.co')
    ) {
      initialUri = `https://w2mgames.com/play?src=${encodeURIComponent(
        initialUri
      )}`;
    }
    window.open(initialUri);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickSearchAppStore}
        label="Search App Store"
      />
      <Button
        color="primary"
        onClick={handleClickSearchGooglePlay}
        label="Search Google Play"
      />
      <Button
        color="primary"
        onClick={handleClickSearchYoutube}
        label="Search Youtube"
      />
      <Button
        color="primary"
        onClick={handleClickSearchImages}
        label="Search Images"
      />
      {props.data?.metadata?.uri && (
        <Button color="primary" onClick={handleClickOpenUri} label="Open URI" />
      )}
      {props.data?.metadata?.initialUri && (
        <Button
          color="primary"
          onClick={handleClickOpenInitialUri}
          label="Open Initial URI"
        />
      )}
    </>
  );
};
