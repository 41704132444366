import React from 'react';
import {
  Edit,
  EditActionsProps,
  EditProps,
  FormTab,
  required,
  SelectInput,
  ShowButton,
  TabbedForm,
  TextInput,
  TopToolbar,
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  BooleanInput,
  NumberField,
  NumberInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLLECTION_APP,
  COLLECTION_APP_SCREENSHOT,
  AppOrientationOptions,
  ResourceStatusOptions,
  ResourceStoreStatusOptions,
} from '../utils';
import { LinkField, VideoField } from '../components/fields';
import {
  ImageListInput,
  JsonInput,
  CustomImageInput,
} from '../components/inputs';
import { CustomToolbar } from '../components/toolbars';
import AppResourcePublishButton from './AppResourcePublishButton';
import AgentCreateJobButton from './AgentCreateJobButton';
import AppResourceAuditButton from './AppResourceAuditButton';
import AppResourcePublishGooglePlayButton from './AppResourcePublishGooglePlayButton';
import { ActionResourceButtons } from './AppResourceButtons';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  image: {
    width: 128,
    height: 128,
  },
  input: {
    width: '33%',
    marginLeft: '2px',
  },
});

const AppResourceEditActions: React.FC<EditActionsProps> = props => {
  return (
    <TopToolbar>
      <ActionResourceButtons {...props} />
      <ShowButton basePath={props.basePath} record={props.data} />
    </TopToolbar>
  );
};

const AppResourceEdit: React.FC<EditProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  const classes = useStyles();
  return (
    <Edit
      {...props}
      actions={<AppResourceEditActions />}
      mutationMode={'pessimistic'}
    >
      <TabbedForm
        redirect="show"
        toolbar={
          canModerate ? (
            <CustomToolbar saveable>
              <AppResourcePublishButton />
              <AppResourcePublishGooglePlayButton />
              <AppResourceAuditButton />
              <AgentCreateJobButton />
            </CustomToolbar>
          ) : (
            <></>
          )
        }
      >
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <ReferenceManyField
            reference={COLLECTION_APP_SCREENSHOT}
            target="appId"
            label="resources.app_screenshot.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_SCREENSHOT}
              />
              {canModerate && (
                <LinkField
                  source="appId"
                  reference={COLLECTION_APP}
                  label="App"
                />
              )}
              <TextField source="deviceDisplay" />
              <TextField source="status" />
              <DateField source="updatedAt" />
              <DateField source="updatedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>

          {canModerate && (
            <LinkField label="App" source="id" reference={COLLECTION_APP} />
          )}

          <TextField label="Owner" source="metadata.owner" />
          <TextField label="Orientation" source="metadata.orientation" />
          <NumberField source="accountId" />
          <TextField source="queueName" />
          <TextInput source="name" />
          <TextInput source="title" />
          <TextInput source="shortDescription" fullWidth />
          <TextInput source="description" fullWidth multiline />
          <CustomImageInput source="image" dirPath="images" />
          <SelectInput source="status" choices={ResourceStatusOptions} />
          <>
            <SelectInput
              className={classes.input}
              source="metadata.orientation"
              choices={AppOrientationOptions}
            />
            <TextInput className={classes.input} source="metadata.owner" />
          </>
          <TextInput source="metadata.initialUri" />
          <TextInput source="metadata.referer" />
          {canModerate && <JsonInput id="metadata" source="metadata" />}
          <ImageListInput
            label="Custom Images"
            source="screenshots.customImages"
          />
          <VideoField
            label="Walkthrough Video"
            source="videos.walkThroughYoutubeId"
          />
          {canModerate && (
            <>
              <JsonInput id="ad" source="ad" />
            </>
          )}
          {canModerate && (
            <>
              <SelectInput
                className={classes.input}
                source="appStore.status"
                choices={ResourceStoreStatusOptions}
              />
              <BooleanInput label="Publishable" source="appStore.publishable" />
              <JsonInput id="appStore" source="appStore" />
            </>
          )}
          <TextInput source="googlePlay.title" />
          <TextInput source="googlePlay.icon" />
          {canModerate && (
            <>
              <NumberInput label="Account ID" source="googlePlay.accountId" />
              <TextInput label="Queue Name" source="googlePlay.queueName" />
              <BooleanInput
                label="Google Play Publishable"
                source="googlePlay.publishable"
              />
              <NumberInput
                label="Google Play Blur Sigma"
                source="googlePlay.blurSigma"
              />
              <SelectInput
                className={classes.input}
                source="googlePlay.status"
                choices={ResourceStoreStatusOptions}
              />
              <JsonInput id="googlePlay" source="googlePlay" />
            </>
          )}
          {canModerate && (
            <>
              <TextInput className={classes.input} source="amazonStore.title" />
              <TextInput className={classes.input} source="amazonStore.appId" />
              <TextInput
                className={classes.input}
                source="amazonStore.releaseId"
              />
              <NumberInput label="Account ID" source="amazonStore.accountId" />
              <TextInput label="Queue Name" source="amazonStore.queueName" />
              <SelectInput
                className={classes.input}
                source="amazonStore.status"
                choices={ResourceStoreStatusOptions}
              />
              <BooleanInput
                label="Amazon Store Publishable"
                source="amazonStore.publishable"
              />
              <JsonInput id="amazonStore" source="amazonStore" />
            </>
          )}
          {canModerate && (
            <>
              <TextInput
                className={classes.input}
                source="samsungGalaxyStore.title"
              />
              <TextInput
                className={classes.input}
                source="samsungGalaxyStore.appId"
              />
              <SelectInput
                className={classes.input}
                source="samsungGalaxyStore.status"
                choices={ResourceStoreStatusOptions}
              />
              <BooleanInput
                label="Samsung Galaxy Store Publishable"
                source="samsungGalaxyStore.publishable"
              />
              <JsonInput id="samsungGalaxyStore" source="samsungGalaxyStore" />
            </>
          )}
          {canModerate && (
            <>
              <TextInput
                label="Title (max 30 chars)"
                inputProps={{
                  maxLength: 30,
                }}
                className={classes.input}
                source="huaweiAppGallery.title"
              />
              <TextInput
                className={classes.input}
                source="huaweiAppGallery.appId"
              />
              <TextInput
                className={classes.input}
                source="huaweiAppGallery.versionId"
              />
              <SelectInput
                className={classes.input}
                source="huaweiAppGallery.status"
                choices={ResourceStoreStatusOptions}
              />
              <TextInput
                fullWidth
                label="Huawei Short Description (max 80 chars)"
                source="huaweiAppGallery.shortDescription"
                inputProps={{
                  maxLength: 80,
                }}
              />
              <JsonInput id="huaweiAppGallery" source="huaweiAppGallery" />
            </>
          )}
          {canModerate && (
            <>
              <TextInput
                className={classes.input}
                source="xiaomiGetApps.title"
              />
              <TextInput
                className={classes.input}
                source="xiaomiGetApps.appId"
              />
              <SelectInput
                className={classes.input}
                source="xiaomiGetApps.status"
                choices={ResourceStoreStatusOptions}
              />
              <TextInput
                fullWidth
                label="Xiaomi Short Description (max 96 chars)"
                source="xiaomiGetApps.shortDescription"
                inputProps={{
                  maxLength: 96,
                }}
              />
              <JsonInput id="xiaomiGetApps" source="xiaomiGetApps" />
            </>
          )}
          {canModerate && (
            <>
              <TextInput
                className={classes.input}
                source="oppoAppMarket.title"
              />
              <TextInput
                className={classes.input}
                source="oppoAppMarket.appId"
              />
              <SelectInput
                className={classes.input}
                source="oppoAppMarket.status"
                choices={ResourceStoreStatusOptions}
              />
              <JsonInput id="oppoAppMarket" source="oppoAppMarket" />
            </>
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AppResourceEdit;
